import { template as template_63543f2828254ff7a7873c533751249f } from "@ember/template-compiler";
const WelcomeHeader = template_63543f2828254ff7a7873c533751249f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
