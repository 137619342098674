import { template as template_d7b181fae75449b9a1f547568a382fa0 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d7b181fae75449b9a1f547568a382fa0(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
