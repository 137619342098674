import { template as template_40757e441b594289b6611f375c1316d2 } from "@ember/template-compiler";
const FKLabel = template_40757e441b594289b6611f375c1316d2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
